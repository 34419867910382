<template>
  <div class="section-product" id="product">
    <div class="container">
      <ProductDetail :category="page" :locale="locale"/>
    </div>
  </div>
</template>

<script>
import ProductDetail from './ProductDetail'

  export default {
  name: 'Product',
  props: {
    page: String,
    locale: String
  },
  components: {
    ProductDetail
  }
}
</script>

<style lang="scss">
  @import '../../assets/scss/mixin/_mixin'; 
  .honey {
    --color: #ec9706;
  }
  .corn {
    --color: #fdd70b;
  }
  .barbecue {
    --color: #ed6d00;
  }
  .butter {
    --color: #0097dc;
  }
  .karamucho {
    .product {
      &-tab {
        &__item {
          &.active,
          &:hover {
            font-family: 'Baloo Bhai', cursive;
            font-display: swap;
            color: #ec3a17;
          }
        }
      }
      &-border {
        &__active {
          background: #ec3a17;
        }
      }
      &-panel {
        &__title {
          color: #ec3a17;
        }
      }
      &-right__content {
        --color: #ec3a17
      }
    }
  }
  .koimucho {
    .product {
      &-tab {
        &__item {

          &.active,
          &:hover {
            font-family: 'Baloo Bhai', cursive;
            font-display: swap; 
          }
        }
        &__title,
        &__name {
          color: var(--color)
        }
      }
      &-border {
        &__active {
          background: #ec9706;
        }
      }
      &-panel {
        &__title {
          color: var(--color);
        }
      }
    }
  }
  .product {
    padding-top: 5rem;
    position: relative;

    @include media(tablet) {
      //transform: translateY(-20rem)
    } 
    @include media(phone) {
      //transform: translateY(-10rem)
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2rem;
    }
    &-left {
      padding: 0 2rem;
      margin-top: 20.5rem;
      width: 28%;

      @include media(tablet) {
        padding: 0 2rem 0;
        margin-top: 0;
        margin-bottom: 10rem;
        width: 100%;

        &__img {
          display: none
        }
      }
      @include media(phone) {
        width: 100%;
        padding: 0 4rem;
        margin-bottom: 9rem;
        margin-top: 0;

        &__img {
          display: none
        }
      }
      &__content {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding-top: 2rem;
      }
      &__img {
        text-align: center;
        padding-top: 4.5rem;
        max-width: 32rem;
      }
    }
    &-right {
      padding: 0 2rem;
      width: 72%;

      @include media(tablet) {
        width: 100%;
        padding: 0;
      }
      @include media(phone) {
        width: 100%;
        padding: 0;
      }
      &__content {
        padding: 0 3rem 7rem;
        background: #f4f4f4;

        @include media(phone) {
          padding: 0 1.4rem 2.5rem;
        }
      }
    }
    &-tab {
      width: 100%;
      //overflow-x: auto;
      
      &__inner {
        display: flex;
        justify-content: space-between;
        transform: translateY(-5rem);
        margin: 0 -1rem;

        [data-num="5"] {
          width: 20%;
          .product-tab__name {
            font-size: 1.3rem;

            @include media(set-max-w, phone) {
              font-size: 1.2rem;
            }
          }
        }
        [data-num="4"] {
          width: 25%;
          
          @include media(set-max-w, 800) {
            width: 25%;
          }
        }
        [data-num="3"] {
          width: 33.3333%;
        }
        @include media(set-max-w, phone) {
          align-items: flex-end;
        }
      }
      &__item {
        text-align: center;
        color: #928f8f;
        cursor: pointer;
        padding: 0 1rem;
      }
      &__img {
        margin-bottom: 1.5rem;
      }
      &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        line-height: 2rem;
        min-height: 4rem;
        transition: .4s ease-in-out;

        @include media(phone) {
          display: none;
          font-size: 1.2rem;
          line-height: 1.6rem;
          min-height: 4.8rem;
          white-space: nowrap;
        }
      }
    }
    &-border {
      position: relative;
      width: 100%;
      height: 2px;
      background: #e1e1e1;
      transform: translateY(-4.5rem);

      @include media(phone) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }
      &__active {
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
    &-panel {
      padding: 2rem 5rem;

      @include media(phone) {
        padding: 0 1rem ;
      }
      &__title {
        text-transform: uppercase;
        font-family: 'Baloo Bhai', cursive;
        font-display: swap;
        font-size: 3rem;
        font-weight: 700;
        color: #ec3a17;
        margin-bottom: 2rem;
        line-height: 4rem;

        @include media(phone) {
          font-size: 2.4rem;
          margin-bottom: 2rem;
        }
      }
      &__txt {
        font-size: 1.6rem;
        line-height: 3rem;
        margin-bottom: 3.5rem;

        > p {
          position: relative;
          padding-left: 20px;
          line-height: 1.6;

          &:not(:last-child) {
            margin-bottom: 5px
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: var(--color);
          }
        }
        @include media(phone) {
          font-size: 1.4rem;
          line-height: 3rem;
          margin-bottom: 2rem;
        }
      }
      &__info {
        .table {
          font-size: 1.6rem;
          border:  2px solid #000;
          padding: 2.5rem 0 0;
          border-radius: 5px;

          @include media(phone) { 
            font-size: 1.3rem
          }
          &-title {
            
            &:nth-child(2) {
              margin-bottom: 1.5rem;

              @include media(phone) {
                margin-bottom: 1rem;
              }
            }
            &--large {
              line-height: 3.2rem;
              font-size: 3.8rem;
              font-family: 'Baloo Bhai', cursive;
              font-display: swap;

              @include media(phone) {
                font-size: 1.8rem;
                line-height: 2.2rem
              }
            }
            &--medium {
              font-size: 2.3rem;
              
              @include media(phone) {
                font-size: 1.8rem;
              }
            }
          }
          &-head {
            padding: 0 2.5rem 2.5rem;
            border-bottom: 2px solid #000;

            @include media(phone) {
              padding: 0 1.2rem 2rem
            }
          }
          &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .5rem 2.5rem .3rem;
            border-bottom: 1px solid #000;

            @include media(phone) {
              padding: .5rem 1.2rem .3rem;
            }
            &:last-child {
              border: none
            }
          }
          &-col {
            width: 100%;
          }
          &-col-2 {
            display: inline-block;
          }
        }
      }
      &__control {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        line-height: 3rem;
        margin-top: 4.5rem;
        text-decoration: underline;
        cursor: pointer;

        @include media(phone) {
          margin-top: 3.5rem;
        }
      }
    }
  }
</style>

