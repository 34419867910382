<template>
  <div class="product">
    <div class="product-row">
      <div class="product-left">
        <div class="product-left__content">
          <img :src="webp(require(`../../../public/img/detail/${category}/txt-product-${$i18n.locale}.png`))" alt="product">
          <div class="product-left__img">
            <img :src="webp(require(`../../../public/img/detail/${category}/product/${tabActive + 1}.png`))" :alt="selectedProduct.name_title">
          </div>
        </div>
      </div>
      <div class="product-right">
        <div class="product-right__content">
          <div class="product-tab">
            <div class="product-tab__inner">
              <div class="product-tab__item"
                    v-for="(item, index) in product"
                    :key="index" :class="{active: tabActive===index}"
                    :data-tab="index + 1"
                    :data-num="numTab"
                    @click="tabClick(index)">
                <div class="product-tab__item__inner">
                  <div class="product-tab__img">
                    <img :src="webp(require(`../../../public/img/detail/${category}/img-tab-${index + 1}.png`))" :alt="item.name_title">
                  </div>
                  <div class="product-tab__name" v-html="item.name_tab" :class="item.class"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-border">
            <div class="product-border__active" :style="{background: selectedProduct.color}"></div>
          </div>
          <div class="product-panel">
            <div class="product-panel__content" :class="selectedProduct.class">
              <div class="product-panel__item">
                <div class="product-panel__title" :style="{color: selectedProduct.color}" v-html="selectedProduct.name_title"></div>
                <div class="product-panel__txt" v-html="selectedProduct.desc"></div>
                <div class="product-panel__info">
                  <div class="table">
                    <div class="table-head">
                      <div class="table-title table-title--large" v-html="selectedProduct.name_en" :style="{color: selectedProduct.color}"></div>
                      <div class="table-title table-title--large" :style="{color: selectedProduct.color}">{{selectedProduct.flavor}}</div>
                      <div class="table-title table-title--medium">{{ txt.nutrition_fact }}</div>
                      <div class="table-title">{{ txt.nutrition_value }}</div>
                    </div>
                    <div class="table-body">
                      <div class="table-row">
                        <div class="table-col-2"><strong>{{ txt.calories}} {{selectedProduct.calories}}</strong></div>
                        <div class="table-col-2"><strong>Calories from Fat {{selectedProduct.calories_from_fat}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col-2"></div>
                        <div class="table-col-2"><strong>{{ txt.calories_from_fat }}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col-2"><strong>{{ txt.total_fat }}</strong> {{selectedProduct.total_fat}}</div>
                        <div class="table-col-2"><strong>{{selectedProduct.total_fat_percent}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col-2">{{ txt.saturated_fat }} {{selectedProduct.saturated_fat}}</div>
                        <div class="table-col-2"><strong>{{selectedProduct.saturated_fat_percent}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{ txt.trans_fat }}: {{ selectedProduct.trans_fat }}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col-2"><strong>{{ txt.sodium }}</strong> {{selectedProduct.sodium}}</div>
                        <div class="table-col-2"><strong>{{selectedProduct.sodium_percent}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col-2"><strong>Total carbohydrate</strong>  {{selectedProduct.total_carbohydrate}}</div>
                        <div class="table-col-2"><strong>{{selectedProduct.total_carbohydrate_percent}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{ txt.sugars }} {{selectedProduct.sugars}}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col"><strong>{{ txt.protein }}</strong>  {{selectedProduct.protein}}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{ txt.daily_value }}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col" style="text-align: center"><strong>{{selectedProduct.product_name}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col"><strong>{{ txt.ingredients }}:</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{selectedProduct.ingredients}}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col"><strong> &lt;{{ txt.attention }}&gt;: {{selectedProduct.notice}}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col"><strong>{{ txt.instruction_for_usage }}</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{ txt.instruction_for_usage_detail }}</div>
                      </div>
                      <div class="table-row">
                        <div class="table-col"><strong>{{ txt.instruction_for_storage }}:</strong></div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">{{ txt.instruction_for_storage_detail }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-panel__control">
                <div class="back" @click="tabActive--" :class="{disable: tabActive===0}" v-scroll-to="'#product'">{{ $t('back') }}</div>
                <div class="next" @click="tabActive++" :class="{disable: tabActive===product.length-1}" v-scroll-to="'#product'">{{ $t('next') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    category: {
      type: String,
      required: true
    },
    locale: String
  },
  data() {
    return {
      product: this.$t(`${this.category}.product`),
      txt: this.$t('ingredients_text'),
      tabActive: 0,
      isAmimate: false
    }
  },
  computed: {
    numTab() {
      return this.product.length || 0
    },
    selectedProduct() {
      return this.product[this.tabActive]
    }
  },
  watch: {
    tabActive: function() {
      this.borderActive()
    },
    locale: function() {
      this.product = this.$t(`${this.category}.product`)
      this.txt = this.$t('ingredients_text')
    }
  },
  mounted() {
    window.addEventListener('load', this.borderActive)
    this.borderActive()
  },
  methods: {
    borderActive() {
      let tabItemWidth = document.querySelector('.product-tab__item').clientWidth,
              elLeft = 100 * this.tabActive / this.product.length,
              borderDash = document.querySelector('.product-border__active');
      borderDash.style.cssText = `left: ${elLeft}%; width: ${tabItemWidth}px`
    },
    tabClick: function(n) {
      this.tabActive = parseInt(n);
    }
  }
}
</script>